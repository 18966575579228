<template>
  <div>
    <b-card>
      <!-- BODY -->
      <validation-observer ref="refMixedForm">
        <!-- form -->
        <b-form
          :style="{ height: trHeight }"
          class="repeater-form mt-2"
        >
          <h6 class="d-block text-capitalize mb-75">
            How long have you been using FreeStyle Libre® / FreeStyle Libre® 2?
          </h6>
          <b-row ref="row">
            <b-col md="6">
              <b-form-group
                label="Please Select*"
                label-for="current_device_use_duration"
              >
                <validation-provider
                  #default="{errors}"
                  name="current device duration"
                  rules="required"
                >
                  <v-select
                    v-model="userLocal.current_device_use_duration"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="duration_current_device_used_list"
                    :clearable="false"
                    input-id="current_device_use_duration"
                    :reduce="val => val.value"
                    aria-required="true"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>
          <h6 class="d-block text-capitalize mb-75">
            What is your Hba1c before and after using FreeStyle Libre® / FreeStyle Libre® 2?
          </h6>
          <b-row>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                name="glucose hba1c % "
                rules="required"
              >
                <b-form-group
                  label="What is your Hba1c before using FreeStyle Libre®*"
                  label-for="glucose_hba1c_before_libre"
                >
                  <b-form-input
                    id="glucose_hba1c_before_libre"
                    v-model="userLocal.glucose_hba1c_before_libre"
                    :state="errors.length > 0 ? false:null"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group></validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                name="glucose hba1c date"
                rules="required"
              >
                <b-form-group
                  label="Before starting FreeStyle Libre® / FreeStyle Libre® 2* HBA1C Date*"
                  label-for="glucose_hba1c_date"
                >
                  <b-form-datepicker
                    id="glucose_hba1c_date"
                    v-model="userLocal.glucose_hba1c_date"
                    class="mb-1"
                    :state="errors.length > 0 ? false:null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group></validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                name="glucose hba1c after libre"
                rules="required"
              >
                <b-form-group
                  label="What is your Hba1c after starting FreeStyle Libre® / FreeStyle Libre® 2*"
                  label-for="glucose_hba1c_after_libre"
                >
                  <b-form-input
                    id="glucose_hba1c_after_libre"
                    v-model="userLocal.glucose_hba1c_after_libre"
                    :state="errors.length > 0 ? false:null"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group></validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                name="glucose hba1c recent date"
                rules="required"
              >
                <b-form-group
                  label="After Starting FreeStyle Libre® / FreeStyle Libre® 2* HBA1C Date*"
                  label-for="glucose_hba1c_date_recent"
                >
                  <b-form-datepicker
                    id="glucose_hba1c_date_after"
                    v-model="userLocal.glucose_hba1c_date_recent"
                    :state="errors.length > 0 ? false:null"
                    class="mb-1"
                    required
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <h6 class="d-block text-capitalize mb-75">
            How many hospital visits have you had?
          </h6>
          <b-row ref="row">
            <b-col sm="6">
              <b-form-group
                label="Number of Hospitalisations - BEFORE using FreeStyle Libre®*"
                label-for="number_of_hospitalisation"
              >
                <validation-provider
                  #default="{errors}"
                  name="number of hospitalisation"
                  rules="required"
                >
                  <v-select
                    v-model="userLocal.number_of_hospitalisation"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="numericalOptionList"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="number_of_hospitalisation"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col sm="6">
              <b-form-group
                label="Number of Hospitalisations - WHILE/AFTER using FreeStyle Libre® / FreeStyle Libre® 2*"
                label-for="number_of_hospitalisation_while_using_libre"
              >
                <validation-provider
                  #default="{errors}"
                  name="number of hospitalisation while using FreeStyle Libre"
                  rules="required"
                >
                  <v-select
                    v-model="userLocal.number_of_hospitalisation_while_using_libre"
                    :state="errors.length > 0 ? false:null"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="numericalOptionList"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="number_of_hospitalisation_while_using_libre"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <h6 class="d-block text-capitalize mb-75">
            How many strips you used per day?
          </h6>
          <b-row>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                name=" strips per day"
                rules="required"
              >
                <b-form-group
                  label="Strips used before using FreeStyle Libre®*"
                  label-for="avg_smbg_test_before_libre"
                >
                  <v-select
                    v-model="userLocal.avg_smbg_test_before_libre"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="numericalOptionList"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="avg_smbg_test_before_libre"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                name="strips while using libre"
                rules="required"
              >
                <b-form-group
                  label="Strips used while using FreeStyle Libre® / FreeStyle Libre® 2*"
                  label-for="strips_while_using_libre"
                >
                  <v-select
                    v-model="userLocal.strips_while_using_libre"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="numericalOptionList"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="strips_while_using_libre"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group></validation-provider>
            </b-col>
          </b-row>
          <h6 class="d-block text-capitalize mb-75">
            How do you administer insulin daily injections? Please specify insulin pump
          </h6>
          <validation-observer
            ref="refFormPatientInsulinPharmacyList"
          >
            <b-form
              :style="{ height: trHeight }"
              class="repeater-form mt-2"
            >
              <b-row>
                <b-col md="4">
                  <b-form-group
                    label=""
                    label-for="insulin_pumps"
                  >
                    <validation-provider
                      #default="{errors}"
                      name="insulin pumps"
                      rules="required"
                    >
                      <v-select
                        v-model="userLocal.insulin_pump"
                        required
                        aria-required="true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="insulinPumpList"
                        :clearable="false"
                        input-id="insulin_pump"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="userLocal.insulin_pump && userLocal.insulin_pump.label == 'Other'"
                  sm="10"
                >
                  <b-form-group
                    label="Please specify other Insulin Pump"
                    label-for="other_insulin_pump"
                  >
                    <b-form-input
                      id="other_insulin_pump"
                      v-model="userLocal.other_insulin_pump"

                      placeholder="Please specify other type "
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
          <b-row ref="row">
            <b-col md="10">
              <b-form-group
                label="Patient Comments"
                label-for="patient_comments"
              >
                <!-- <validation-provider
                  #default="{errors}"
                  name="Patient Comments"
                  rules="required"
                > -->
                <b-form-textarea
                  id="textarea-default"
                  v-model="userLocal.patient_comments"
                  placeholder=""
                  rows="3"
                />
                <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
              </b-form-group>
            </b-col>

            <b-col md="10">
              <b-form-group
                label="CDE Comments"
                label-for="cde_comments"
              >
                <b-form-textarea
                  id="textarea-default"
                  v-model="userLocal.healthcare_professional_comments"
                  disabled
                  placeholder=""
                  rows="3"
                />
              </b-form-group>
            </b-col>

            <b-col md="10">
              <b-form-group
                label="Pharmacist Comments"
                label-for="pharmacy_comments"
              >
                <!-- <validation-provider
                  #default="{errors}"
                  name="Pharmacy comments"
                  rules="required"
                > -->
                <b-form-textarea
                  id="textarea-default"
                  v-model="userLocal.pharmacy_comments"
                  placeholder=""
                  rows="3"
                />
                <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
              </b-form-group>
            </b-col>
          </b-row>
          <b-row ref="row">
            <b-col sm="10">
              <b-form-group
                label="Doctor/Practitioner's Name"
                label-for="doctor_name"
              >
                <b-form-input
                  id="doctor_name"
                  v-model="userLocal.doctor_name"
                  placeholder="Add the name of the doctor for Refferal Letter"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="How satisfied are you with FreeStyle Libre/FreeStyle Libre 2?*"
                label-for="satisfied_ans"
              >
                <validation-provider
                  #default="{errors}"
                  name="satisfied answer"
                  rules="required"
                >
                  <v-select
                    v-model="userLocal.satisfied_ans"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="satisfied_list"
                    :clearable="false"
                    input-id="satisfied_ans"
                    :reduce="val => val.value"
                    aria-required="true"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BFormInput,
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BFormTextarea,
  BFormDatepicker,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import store from '@/store'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'

export default {
  components: {
    BFormTextarea,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    vSelect,
    BFormDatepicker,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      profileFile: null,
      patientPreAssignedDetail: [],
      medicationSelected: [],
      medicationBrandSelected: [],
      careTeamList: [],
      hospitalisationList: [],
      diabetesComplicationsList: [],
      required,
      alphaNum,
      nameState: null,
      patientData: null,
      libreReasonList: [],
      libreStopReasonList: [],
      insulinPumpList: [],
      visitLocal: {
        type: Object,
        default: () => {},
      },
      nextTodoId: 2,
      still_using_freestyle_list: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
        { label: 'Not Applicable', value: 'Not Applicable' },
      ],
      number_of_sensorsList: [
        { label: '0', value: '0' },
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
        { label: '6', value: '6' },
        { label: '7', value: '7' },
        { label: '8 or more', value: '8 or more' },
        { label: 'Not Applicable', value: 'Not Applicable' },
      ],
      number_of_previous_hospitalisations: [
        { label: '0', value: '0' },
        { label: '1', value: '1' },
        { label: '2', value: '1' },
        { label: '3 or more', value: '3 or more' },
        { label: 'Not Applicable', value: 'Not Applicable' },
      ],
      current_devices_list: [
        { label: 'FreeStyle Libre', value: 'FreeStyle Libre' },
        { label: 'FreeStyle Libre 2', value: 'FreeStyle Libre 2' },
        { label: 'Dexcom G4', value: 'Dexcom G4' },
        { label: 'Dexcom G5', value: 'Dexcom G5' },
        { label: 'Dexcom G6', value: 'Dexcom G6' },
        { label: 'Medtronic Guardian 2/Enlite sensor', value: 'Medtronic Guardian 2/Enlite sensor' },
        { label: 'Medtronic Guardian 3', value: 'Medtronic Guardian 3' },
        { label: 'Blood glucose meter', value: 'Blood glucose meter' },
        { label: 'Other', value: 'Other' },
      ],
      switch_to_freestyle_question_list: [
        { label: 'No', value: 'No' },
        { label: 'Yes - Previously using FreeStyle Libre', value: 'Yes - Previously using FreeStyle Libre' },
        { label: 'Yes - Previously using Dexcom G4', value: 'Yes - Previously using Dexcom G4' },
        { label: 'Yes - Previously using Dexcom G5', value: 'Yes - Previously using Dexcom G5' },
        { label: 'Yes - Previously using Dexcom G6', value: 'Yes - Previously using Dexcom G6' },
        { label: 'Yes - Previously using Medtronic Enlite', value: 'Yes - Previously using Medtronic Enlite' },
        { label: 'Yes - Previously using Medtronic Guardian 3', value: 'Yes - Previously using Medtronic Guardian 3' },
      ],
      duration_current_device_used_list: [
        { label: 'Not Applicable', value: 'Not Applicable' },
        { label: 'Less than 2 Months', value: 'less than 2 Months' },
        { label: '2-6 Months', value: '2-6 Months' },
        { label: '7-12 Months', value: '7-12 Months' },
        { label: '12 Months or more', value: '12 Months or more' },
      ],
      satisfied_list: [
        { label: 'Not Applicable', value: 'Not Applicable' },
        { label: 'Very Satisfied', value: 'Very Satisfied' },
        { label: 'Satisfied', value: 'Satisfied' },
        { label: 'Dissatisfied', value: 'Dissatisfied' },
        { label: 'Very Dissatisfied', value: 'Very Dissatisfied' },
      ],
      numericalOptionList: [
        { label: '0', value: '0' },
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
        { label: '6', value: '6' },
        { label: '7', value: '7' },
        { label: '8', value: '8' },
        { label: 'Don\'t know/Depends', value: 'Don\'t know/Depends' },
        { label: 'Not Applicable', value: 'Not Applicable' },
      ],
      userLocal: {
        type: Object,
        default: () => {},
      },
      NdssApprovedOptions: [
        { label: 'T1 < 21 years', value: 'T1 < 21 years' },
        {
          label: 'T1 > 21 years + concession',
          value: 'T1 > 21 years + concession',
        },
        {
          label: 'T1 planning pregnancy/pregnant/post pregnancy',
          value: 'T1 planning pregnancy/pregnant/post pregnancy',
        },
        {
          label: 'Other diabetes > 21 years',
          value: 'Other diabetes > 21 years',
        },
        { label: 'Not Applicable', value: 'Not Applicable' },
      ],
      // insulin_pump: { label: null, value: null },
      // other_insulin_pump: null,
    }
  },
  mounted() {
    this.initTrHeight()
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },

  created() {
    window.addEventListener('resize', this.initTrHeight)
    this.fetchCareTeamProfList()
    this.fetchHospitalisationList()
    this.fetchDiabetesComplicationList()
    this.fetchLibreReasonList()
    this.fetchLibreStopReasonList()
    this.fetchInsulinPumpList()
    this.userLocal.user_pub_id = this.userData.id
    if (this.userData.last_visit !== null) {
      // this.userLocal = this.userData.last_visit
      this.userLocal.number_of_hospitalisation_while_using_libre = this.userData.last_visit.number_of_hospitalisation_while_using_libre
      this.userLocal.number_of_hospitalisation = this.userData.last_visit.number_of_hospitalisation
      const insulinPumpName = this.userData.last_visit.insulin_pump !== null ? this.userData.last_visit.insulin_pump.name : null
      const insulinPumpValue = this.userData.last_visit.insulin_pump !== null ? this.userData.last_visit.insulin_pump.pub_id : null
      this.userLocal.insulin_pump = { label: insulinPumpName, value: insulinPumpValue }
      this.userLocal.current_device_use_duration = this.userData.last_visit.current_device_use_duration
      this.userLocal.pharmacy_comments = this.userData.last_visit.pharmacy_comments
      this.userLocal.satisfied_ans = this.userData.last_visit.satisfied_ans
      this.userLocal.doctor_name = this.userData.last_visit.doctor_name
      this.userLocal.cde_comments = this.userData.last_visit.healthcare_professional_comments
      this.userLocal.patient_comments = this.userData.last_visit.patient_comments
    } if (this.userData.glucose_libre_data !== null) {
      this.userLocal.glucose_hba1c_before_libre = this.userData.glucose_libre_data.glucose_hba1c_before_libre
      this.userLocal.glucose_hba1c_date = this.userData.glucose_libre_data.glucose_hba1c_date
      this.userLocal.glucose_hba1c_after_libre = this.userData.glucose_libre_data.glucose_hba1c_after_libre
      this.userLocal.glucose_hba1c_date_recent = this.userData.glucose_libre_data.glucose_hba1c_date_recent
      this.userLocal.avg_smbg_test_before_libre = this.userData.glucose_libre_data.avg_smbg_test_before_libre
      this.userLocal.strips_while_using_libre = this.userData.glucose_libre_data.strips_while_using_libre
      this.userLocal.userLocal.freestyle_libre_reason = this.userData.glucose_libre_data.freestyle_libre_reason
      this.userLocal.ndss_approved_criteria = this.userData.glucose_libre_data.ndss_approved_criteria
    } else {
      this.userLocal = {
        pharmacy_care_team: null,
        diabetes_complications: null,
        number_of_hospitalisation: null,
        insulin_pump: { label: '', value: '' },
        other_insulin_pump: null,
        still_using_freestyle_list: [
          { label: 'Yes', value: 'Yes' },
          { label: 'No', value: 'No' },
        ],
        strips_while_using_libre: null,
      }
    }
    this.userLocal.user_pub_id = this.userData.id
    // this.userLocal.insulin_pump = { label: null, value: null }
    // this.userLocal.other_insulin_pump = null
  },
  methods: {
    refetchData() {
      return this.$forceUpdate()
    },
    fetchInsulinPumpList() {
      store
        .dispatch('insulinPumpStore/fetchInsulinPumpLabels')
        .then(response => {
          this.insulinPumpList = response.data
        })
        .catch(() => {})
    },

    fetchLibreReasonList() {
      store
        .dispatch('libreReasonStore/fetchLibreReasonLabels')
        .then(response => {
          this.libreReasonList = response.data
        })
        .catch(() => {})
    },

    fetchLibreStopReasonList() {
      store
        .dispatch('libreReasonStore/fetchLibreStopReasonLabels')
        .then(response => {
          this.libreStopReasonList = response.data
        })
        .catch(() => {})
    },

    fetchCareTeamProfList() {
      store
        .dispatch('careTeamStore/fetchCareTeamLabels')
        .then(response => {
          this.careTeamList = response.data
        })
        .catch(() => {})
    },
    fetchHospitalisationList() {
      store
        .dispatch('hospitalisationListStore/fetchHospitalisationListLabels')
        .then(response => {
          this.hospitalisationList = response.data
        })
        .catch(() => {})
    },
    fetchDiabetesComplicationList() {
      store
        .dispatch('diabetesComplicationsStore/fetchDiabetesComplicationLabels')
        .then(response => {
          this.diabetesComplicationsList = response.data
        })
        .catch(() => {})
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    validateMandatory() {
      this.$refs.refMixedForm.validate().then(success => {
        if (success) {
          return true
        }
        return false
      })
    },
    saveAllInfoV2() {
      this.$refs.refMixedForm.validate().then(success => {
        if (success) {
          store
            .dispatch('userStore/savePatientCareTeamDataByPharmacy', {
              user_pub_id: this.userLocal.user_pub_id,
              care_team_data: this.userLocal,
            })
            .then(() => {
              this.$bvToast.toast(
                'Information has been saved',
                {
                  title: 'Success',
                  variant: 'success',
                  solid: true,
                },
              )
            })
            .catch(() => {
              this.$bvToast.toast(
                'Looks like some missing data. Please make sure all information is filled up for the patient.',
                {
                  title: 'Error',
                  variant: 'warning',
                  solid: true,
                },
              )
            })
        }
      })
    },
  },
  setup() {
    const blankData = {
      name: '',
      abbr: '',
    }

    const resetData = () => {
      this.userLocal.value = JSON.parse(JSON.stringify(blankData))
      this.nameState = null
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetData,
    )

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
.repeater-form {
  transition: 0.35s height;
}
@import "@core/scss/vue/libs/vue-select.scss";
</style>
