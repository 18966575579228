import { render, staticRenderFns } from "./Patient-MainViewV2.vue?vue&type=template&id=2484ad58&ref=pharmacy_main&"
import script from "./Patient-MainViewV2.vue?vue&type=script&lang=js&"
export * from "./Patient-MainViewV2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports