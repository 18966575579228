<template>
  <b-card>
    <!-- about -->
    <h3>LibreView Login Details</h3>
    <div class="mt-2">
      <b-card-text>
        Practice ID: <strong>{{ librePracticeID }}</strong>
      </b-card-text>
      <b-card-text>
        Username: <strong>{{ libreUserName }}</strong>
      </b-card-text>
      <b-card-text>
        Password: <strong>{{ librePassword }}</strong>
      </b-card-text>
      <h5 class="text-capitalize mb-75">
        Notes
      </h5>
    </div>
  </b-card>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'
import router from '@/router'
import store from '@/store'

export default {
  components: {
    BCard,
    BCardText,
  },
  props: {
    aboutData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      libreDetails: null,
    }
  },
  computed: {
    librePracticeID() {
      return this.libreDetails !== null ? this.libreDetails.practice_id : null
    },
    libreUserName() {
      return this.libreDetails !== null ? this.libreDetails.user_name : null
    },
    librePassword() {
      return this.libreDetails !== null ? this.libreDetails.password : null
    },
  },
  created() {
    this.fetchLibreLogins()
  },
  methods: {
    fetchLibreLogins() {
      store
        .dispatch('userStore/getPharmacyLibreLogins', {
          pid: router.currentRoute.params.pid,
        })
        .then(response => {
          this.libreDetails = response.data
        })
        .catch(() => {})
    },
  },
}
</script>
