<template>
  <b-card>
    <h5 class="text-capitalize mb-75">
      General Information
    </h5>
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- form -->
      <b-form
        class="mt-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col sm="6">
            <!-- Full Name -->
            <validation-provider
              #default="{ errors }"
              name="date of service"
              rules="required"
            >
              <b-form-group
                label="Date of service*"
                label-for="date_of_service"
              >
                <b-form-datepicker
                  id="date_of_service"
                  v-model="visitLocal.date_of_service"
                  class="mb-1"
                  :state="errors.length > 0 ? false:null"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col sm="6">
            <b-form-group
              label="NDSS Number"
              label-for="ndss_number"
            >
              <b-form-input
                id="ndss_number"
                v-model="visitLocal.ndss_number"
                trim
                placeholder="Patient NDSS Number"
              />
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              label="Phone"
              label-for="phone_number"
            >
              <b-form-input
                id="phone_number"
                v-model="visitLocal.phone"
                trim
                placeholder="Patient Contact Number"
              />
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              label="Gender"
              label-for="gender"
            >
              <validation-provider
                #default="validationContext"
                name="gender"
                rules=""
              >
                <v-select
                  v-model="visitLocal.gender"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="genderOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="gender"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BFormInput,
  BForm,
  BFormGroup,
  BFormDatepicker,
  BRow,
  BCol,
  BCard,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
// import router from '@/router'
import store from '@/store'
import vSelect from 'vue-select'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormInput,
    BFormDatepicker,
    BRow,
    BCol,
    BCard,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    patientData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isSaving: false,
      profileFile: null,
      cdeHcpList: [],
      required,
      alphaNum,
      nameState: null,
      isValid: false,
      // patientData: null,
      genderOptions: [
        { label: 'Male', value: 'Male' },
        { label: 'Female', value: 'Female' },
        { label: 'Other', value: 'Other' },
      ],
      visitLocal: {
        type: Object,
        default: () => {},
      },
    }
  },
  created() {
    this.fetchCdeHcps()
    this.fetchPatientInfo()
  },
  methods: {
    refetchData() {
      return this.$forceUpdate()
    },

    fetchPatientInfo() {
      this.visitLocal.ndss_number = this.patientData.ndss_number
      this.visitLocal.user_pub_id = this.patientData.id
      this.visitLocal.date_of_service = this.patientData.last_visit !== null
        ? this.patientData.last_visit.date_of_service
        : null
      this.visitLocal.gender = this.patientData.gender
      this.visitLocal.phone = this.patientData.phone
    },
    fetchCdeHcps() {
      store
        .dispatch('userStore/fetchCdeHcpLabels')
        .then(response => {
          this.cdeHcpList = response.data
        })
        .catch(() => {})
    },
    validateMandatory() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          return true
        }
        return false
      })
    },
    savePatientGeneralInfo() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.isSaving = true
          store
            .dispatch('userStore/savePatientGeneralData', this.visitLocal)
            .then(() => {
              // this.$bvToast.toast('Data has been saved', {
              //   title: 'Success',
              //   variant: 'success',
              //   solid: true,
              // })
              // this.isSaving = false
            })
            .catch(() => {
              this.$bvToast.toast(
                'We could not process your action this time. please try again.',
                {
                  title: 'Error',
                  variant: 'danger',
                  solid: true,
                },
              )
            })
        }
      })
    },
  },
  setup() {
    const blankData = {
      name: '',
      abbr: '',
    }

    const resetData = () => {
      this.visitLocal.value = JSON.parse(JSON.stringify(blankData))
      this.nameState = null
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetData,
    )

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
