/* eslint-disable no-restricted-globals */ /* eslint-disable
no-unused-expressions */
<template ref="pharmacy_main">
  <b-overlay
    :show="isSaving"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <section id="main_pat">
      <b-row>
        <b-col
          lg="3"
          cols="12"
          order="2"
          order-lg="1"
        >
          <pharmacy-info :about-data="patientPharmacyData" />
        </b-col>
        <b-col
          lg="9"
          cols="12"
          order="1"
          order-lg="2"
        >
          <pharmacy-libre-info :about-data="patientData" />
        </b-col>
      </b-row>
    </section>
    <div id="patient-details">
      <section id="profile-info">
        <b-row>
          <!-- about suggested page and twitter feed -->
          <b-col
            lg="3"
            cols="12"
            order="2"
            order-lg="1"
          >
            <patient-info :about-data="patientData" />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mt-2 mr-1"
              @click.prevent="saveAll"
            >
              Save Details
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="mt-2 mr-1"
              @click.prevent="downloadFile"
            >
              <feather-icon
                icon="CheckSquareIcon"
                class="mr-50"
              />
              <span class="align-middle">Generate GP/CDE Referral Report</span>
            </b-button>
          </b-col>
          <b-col
            lg="9"
            cols="12"
            order="1"
            order-lg="2"
          >
            <patient-libre-report-upload
              ref="patient_libre_report_upload"
              :user-data="patientData"
            />
          </b-col>
          <b-col
            lg="12"
            cols="12"
            order="1"
            order-lg="2"
          >
            <patient-general-view
              ref="patient_general_view"
              :patient-data="patientData"
            />
            <patient-diabetes-info
              ref="patient_diabetes_info"
              :user-data="patientData"
            />
            <patient-mixed-view
              ref="patient_mixed_view_info"
              :user-data="patientData"
            />

            <patient-diabetes-medicine
              ref="patient_diabetes_medicine"
              :user-id="patientData.id"
            />

            <!-- <patient-insulin
              ref="patient_diabetes_insulin"
              :user-id="patientData.id"
              :recent-changes-in-diabetes-theraphy="recentChangesInDiabetesTheraphyData"
            /> -->

            <!-- <patient-glucose-monitor
              ref="patient_glucose_monitor"
              :user-data="patientData"
            /> -->
            <!-- <patient-care-team-info
              ref="patient_care_team_info"
              :user-data="patientData"
            /> -->
            <b-card>
              <h6 class="text-danger">
                Click “Save Details” then “Generate GP/CDE Referral Report”. Print this report for the patient, attach to their printed LibreView report then go back into MedAdvisor PlusOne and click “Complete” for this Service.
              </h6>
              <b-col md="10">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="mt-2 mr-1"
                  @click.prevent="saveAll"
                >
                  Save Details
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  class="mt-2 mr-1"
                  @click.prevent="downloadFile"
                >
                  <feather-icon
                    icon="CheckSquareIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Generate GP/CDE Referral Report</span>
                </b-button>
              </b-col>
            </b-card>
          </b-col>
        </b-row>
      </section>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BRow, BCol, BButton, BCard,
} from 'bootstrap-vue'
import router from '@/router'
import store from '@/store'

import PharmacyInfo from '@/views/apps/pharmacy/PharmacyInfo.vue'
import PatientInfo from '@/views/apps/user/patient-data-view/PatientInfoSmall.vue'
import PharmacyLibreInfo from '@/views/apps/pharmacy/PharmacyLibreInfo.vue'
// import PatientGlucoseMonitor from '@/views/apps/user/pharmacy-patient-data-view/Patient-GlucoseMonitorView.vue'
// import PatientMedicationInfo from '@/views/apps/user/PatientMedicationInfo.vue'
import PatientDiabetesInfo from '@/views/apps/user/patient-data-view/Patient-DiabetesInfoView.vue'
// import PatientCareTeamInfo from '@/views/apps/user/pharmacy-patient-data-view/Patient-CareTeamInfo.vue'
import PatientMixedView from '@/views/apps/user/pharmacy-patient-data-view/Patient-MixedV2.vue'
// import PatientInsulin from '@/views/apps/user/pharmacy-patient-data-view/Patient-InsulinListView.vue'
import PatientDiabetesMedicine from '@/views/apps/user/pharmacy-patient-data-view/Patient-MedicationListView.vue'
import PatientLibreReportUpload from '@/views/apps/user/pharmacy-patient-data-view/Patient-LibreReportUpload.vue'
import Ripple from 'vue-ripple-directive'
import { initialAbility } from '@/libs/acl/config'
import { isUserLoggedIn } from '@/auth/utils'
import apiConfig from '@/api/apiConfig'
// import { useWindowScroll } from '@vueuse/core'
import PatientGeneralView from './Patient-GeneralView.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BOverlay,
    PatientInfo,
    PatientGeneralView,
    PatientDiabetesInfo,
    // PatientCareTeamInfo,
    // PatientInsulin,
    PatientDiabetesMedicine,
    PharmacyInfo,
    PharmacyLibreInfo,
    // PatientGlucoseMonitor,
    PatientLibreReportUpload,
    PatientMixedView,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      options: {},
      userData: null,
      patientData: null,
      patientPharmacyData: null,
      spinnerShow: true,
      visitCount: 0,
      isSaving: false,
      isPDFCreate: false,
      canSaveData: false,
      downloadReferralVisible: false,
      dataValidated: true,
    }
  },
  computed: {
    currentVisit() {
      return this.visitCount + 1
    },
    recentChangesInDiabetesTheraphyData() {
      if (this.patientData.last_visit !== null) {
        return this.patientData.last_visit.recent_changes_in_diabetes_therapy
      }
      return null
    },
  },
  // watch: {
  //   canSaveData(newVal) {
  //     this.canSaveData = newVal
  //   },
  // },
  mounted() {
    // document.getElementById('#main_pat').scrollIntoView({ behavior: 'smooth' })
    // const { y } = useWindowScroll()

    const rootEle = document.documentElement
    rootEle.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  },
  created() {
    this.isSaving = true
    const isLoggedIn = isUserLoggedIn()
    if (!isLoggedIn) {
      this.logout()
    }
    if (localStorage.getItem('check_reload')) {
      // The page was just reloaded. Clear the value from local storage
      // so that it will reload the next time this page is visited.
      localStorage.removeItem('check_reload')
    } else {
      // Set a flag so that we know not to reload the page twice.
      localStorage.setItem('check_reload', '1')
      // eslint-disable-next-line no-restricted-globals
      // this.$router.go(this.$router.currentRoute)
      this.$forceUpdate()
    }
    this.fetchPatientInfo()
    this.isSaving = false
  },

  methods: {
    logout() {
      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to auto auth page
      this.$router.push({ name: 'auth-login' })
    },
    activateCanSave() {
      this.canSaveData = true
    },
    fetchPatientInfo() {
      store
        .dispatch('userStore/fetchPatientInfo', {
          userId: router.currentRoute.params.id,
        })
        .then(response => {
          const { status } = response
          if (status === 401) {
            this.$bvToast.toast(`ERROR:${response.message}`, {
              title: 'Error',
              variant: 'danger',
              solid: true,
            })
          } else {
            this.patientData = response.data
            this.patientPharmacyData = response.data.pharmacy
            this.canSaveData = this.patientData.libre_report_files.length !== 0
          }
        })
        // .catch(error => {
        //   console.info(error)
        // })
        .catch()
    },
    async hasValidInputs() {
      await this.$refs.patient_general_view.validateMandatory()
      await this.$refs.patient_diabetes_info.validateMandatory()
      await this.$refs.patient_mixed_view_info.validateMandatory()
      await this.$refs.patient_diabetes_medicine.validateMandatory()
    },
    saveAll() {
      this.isSaving = true
      this.$refs.patient_general_view.savePatientGeneralInfo()
      this.$refs.patient_diabetes_info.saveDiabetesTypeInfo()
      this.$refs.patient_mixed_view_info.saveAllInfoV2()
      this.$refs.patient_diabetes_medicine.saveMedications()

      this.isSaving = false
      this.downloadReferralVisible = true
    },
    downloadFile() {
      this.hasValidInputs()
      // Check if libre_report is uploaded and all data for patient is filled.
      this.fetchPatientInfo()
      if (this.canSaveData === true) {
        const url = `${apiConfig.pdfGenerateURL}/patient_referral_download/${this.patientData.id}`
        window.open(url, '_blank')
      } else {
        this.$bvToast.toast(
          'Please make sure you upload the LibreView report and fill up all the required information of the patient.',
          {
            title: 'Error',
            variant: 'danger',
            solid: true,
          },
        )
      }
    },
  },
}
</script>
