<template>
  <b-card>
    <h6 class="text-danger mb-75">
      Complete the following to assist the patient access their LibreView glucose monitoring report, update mediciation profile and share this referral with their regular GP/CDE.
    </h6>
    <h4 class="text mb-75">
      Click on the relevant link (LibreLink App or Reader) for instructions to download the patient’s LibreView report.
    </h4>
    <!-- BODY -->
    <validation-observer
      ref="refFormObserver"
    >
      <!-- form -->
      <b-form
        class="mt-2"
      >
        <b-row>
          <b-col sm="12">
            <app-collapse
              accordion
              type="margin"
            >

              <app-collapse-item
                :is-visible="true"
                title="Patient is using FreeStyle LibreLink App (on smartphone)"
              >
                <ol type="1|a|A|i|I">
                  <li>Log into LibreView <a
                    target="_blank"
                    href="https://www.libreview.com/"
                  >https://www.libreview.com/</a> using LibreView login details noted above for your pharmacy</li>
                  <li>Assist patient to connect with your pharmacy be entering your Practice ID in their LibreLink app (on their mobile phone):
                    <ol>
                      <li>Click on Menu (three lines at top left) > Connected Apps > LibreView ‘Manage’ > Connect to a Practice.</li>
                      <li>Enter Practice ID and click on Next > Connect > Done.<br>
                        <a
                          class="text-info"
                          href="https://lib-view-instructions.s3.ap-southeast-2.amazonaws.com/Instructions+for+LibreView+Practice+connection.pdf"
                          target="_blank"
                        >Instructions For Practice Connection</a></li>
                    </ol>
                  </li>
                  <li>On your LibreView desktop screen, click on Patient Dashboard and select the patient’s name.</li>
                  <li>Click on Glucose Reports then Print/Save PDF.<br>
                    <a
                      class="text-info"
                      href="https://lib-view-instructions.s3.ap-southeast-2.amazonaws.com/Instructions+to+navigate+to+a+patient's+glucose+report.pdf"
                      target="_blank"
                    >Instructions to navigate to a patient’s glucose report</a>
                  </li>
                  <li class="blockquote text-warning">
                    Print LibreView report for patient then upload LibreView report below.
                  </li>
                </ol>
              </app-collapse-item>

              <app-collapse-item
                title="Patient is only using FreeStyle Libre
Reader device (not the phone App)"
              >
                <ol
                  class="li-pad"
                  type="1|a|A|i|I"
                >
                  <li>Log into LibreView <a
                    target="_blank"
                    href="https://www.libreview.com/"
                  >https://www.libreview.com/</a> using LibreView login details noted above for your pharmacy</li>
                  <li>Click the Upload Device icon (top left corner of browser screen).</li>
                  <li>Connect patient reader via USB to computer > click Create Report Linked to Patient.
                    <br><cite>Note: If any problems are detected while finding the device, try another USB port and repeat this step.</cite>

                  </li>
                  <li>Once successfully uploaded, the Freestyle Libre Reader details will be shown in a pop-up > click Continue.</li>
                  <li>In Link Upload to Patient pop-up, click Create a new patient.</li>
                  <li>Enter Patient Name, DOB, email address (if applicable) your Practice ID then click Save. </li>
                  <li>The ‘Confirm Patient Details’ pop-up will be displayed > click Confirm. </li>
                  <li>Click on Glucose Reports then Print/Save PDF.
                    <br>OR If patient has uploaded data previously, go to > Glucose History screen > click Glucose Reports > Print/Save PDF.</li>
                  <li class="blockquote text-warning">
                    Print LibreView report for patient then upload LibreView report below.
                  </li>
                </ol>
                <cite>Now upload the patient’s LibreView PDF report into CarePrograms </cite>
              </app-collapse-item>
            </app-collapse>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            <div class="card-body">
              <h5>Upload Patient's LibreView Report Upload</h5>
              <small>(.pdf or .docx file please)</small>
              <!-- Styled -->
              <b-form-file
                ref="file-input"
                placeholder="Choose the downloaded LibreView Report to upload or drop it here..."
                accept=".pdf, .doc, .docx"
                drop-placeholder="Drop file here..."
                name="libre_report_file"
                @change="uploadReportFile"
              />
              <!-- <div v-if="reportLink !== null">
                {{ reportLink }}
              </div> -->
            </div>
          </b-col>

          <b-col sm="6">
            <div class="card-body">
              <h5>Uploaded Reports List</h5>
              <b-card-text
                v-for="file in reportFiles"
                :id="file.id"
                :key="file.id"
              >
                <ul>
                  <li><strong>{{ file.file_name }}</strong> ({{ file.created_at }})</li>
                </ul>

              </b-card-text>
            </div>
          </b-col>
        </b-row>
        <h6 class="text-warning mb-75">
          Complete the following information below then click "Generate GP/CDE Referral" at the bottom of the screen.
        </h6>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BForm,
  BRow,
  BCol,
  BCard,
  BCardText,
  BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver } from 'vee-validate'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from '@axios'
import apiConfig from '@/api/apiConfig'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BCard,
    AppCollapse,
    AppCollapseItem,
    BFormFile,
    BCardText,
    // Form Validation
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      profileFile: null,
      glucoseMonitorList: [],
      libreReasonList: [],
      required,
      alphaNum,
      assignedData: null,
      nameState: null,
      patientData: null,
      trSetHeight: null,
      assginedData: {
        type: Object,
        default: () => {},
      },
      reportFiles: null,
      userLocal: {
        type: Object,
        default: () => {},
      },
    }
  },
  computed: {
    reportLink() {
      return this.patientData.libre_report_file
    },
  },
  watch: {
    patientData(newVal) {
      this.reportFiles = newVal.libre_report_files
    },
  },
  created() {
    this.reportFiles = this.userData.libre_report_files
    this.userLocal.user_pub_id = this.userData.id
  },
  methods: {

    uploadReportFile(e) {
      const reader = new FileReader()
      const formData = new FormData()
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }

      // Read contents of file that's stored on user's computers
      // https://developer.mozilla.org/en-US/docs/Web/API/FileReader
      // eslint-disable-next-line no-shadow
      reader.onload = e => {
        this.libre_report_file = e.target.result
        // Prepare form data to be sent via AJAX
        // https://developer.mozilla.org/en-US/docs/Web/API/FormData
        formData.append('libre_report_file', files[0])
        // Async request to upload from backend
        axios.post(`${apiConfig.apiUrl}/resources/patient_libre_report_upload/${this.userData.id}`, formData)
          .then(response => {
            this.patientData = response.data
            this.$bvToast.toast('Report Uploaded Successfully', {
              title: 'File Upload',
              variant: 'success',
              solid: true,
            })
            this.$refs['file-input'].reset()
            this.$refs.patient_libre_report_list.refetchPatientData()
            // eslint-disable-next-line no-unused-expressions
            this.$parent.activateCanSave
          })
      }
      // Read contents of the file
      // https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
      reader.readAsDataURL(files[0])
    },
  },
  setup() {
    const blankData = {
      name: '',
      abbr: '',
    }

    const resetData = () => {
      this.assignedData.value = JSON.parse(JSON.stringify(blankData))
      this.nameState = null
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetData,
    )

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

ol li {
  padding:5px;
}
</style>
